import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
// import Layout from "../components/layout"
import SEO from "../components/seo"
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import SageTopLeft from "../images/SageTopLeft"
import SageBottomRight from "../images/SageBottomRight"
// import Subscribe from "../components/subscribe"
// import JJ from "../images/JJ"
// import useScript from '../components/useScript'
// import * as myHtmlSub from '../components/signup2'

const IndexPage = () => {
  // useScript(`https://zcvf-zcglf.maillist-manage.com/js/optin.min.js`, `setupSF('sf3zf22d2d286f00802553ea465633d35b5439025e04ad63c83d81cab0ddf1b12bdf','ZCFORMVIEW',false,'light',false,'0')`)
  // useScript(`https://campaigns.zoho.com/js/zc.iframe.js`)
  // const iframe = `<iframe frameborder="0" id="iframewin" width="310px" height="475px" src="https://zcvf-zcglf.maillist-manage.com/ua/Optin?od=11287ecbf9801c&zx=12ad14ca9&lD=1d70b8b346242028&n=11699f750e1f057&sD=1d70b8b346242a49"></iframe>`
  // const Iframe = (props) => {
  //   return (<div className="flex justify-center" dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />)
  // }
  
  return (
  <div className="w-full" style={{ minHeight: 600 }}>
    <SEO title="Home" />
    {/* <div className="absolute z-10 top-0 right-0">
      <ToastContainer />
    </div> */}
    <div style={{ marginBottom: `1.45rem`, pointerEvents: 'none' }} className="absolute md:fixed w-60 md:w-4/12 max-w-lg z-10">
      <SageTopLeft />
    </div>
    <div className="absolute z-0 m-3" style={{
      top: '0',
      bottom: '0',
      right: '0',
      left: '0',
      marginTop: '3.5em'
    }}>
      <div className="shadow-xl flex flex-col items-center justify-center relative md:mx-12" style={{ 
        background: 'rgb(248, 246, 242)', //rgba(150, 150, 150, 0.2) //rgb(23 67 122 / 24%)
        minHeight: '90vh',
        minWidth: '90vw'
      }}>
      <a href="/" className="hidden md:block"><h1 className="gold-text text-xl md:text-4xl absolute top-0 right-0 p-2" style={{ transform: 'rotate(90deg) translate(4em, -2.5em)' }}>JoeAndJayna.com</h1></a>
      <div className="text-body m-8 mt-12 text-center" style={{ maxWidth: '630px' }}>
        <div className="welcome-heading flex flex-col items-center justify-center">
          <p className="font-roman text-3xl md:text-6xl tracking-widest uppercase">Welcome</p>
          <p className="font-cursive text-2xl daggers">to</p>
          <p className="font-roman text-xl tracking-widest uppercase">JoeAndJayna.com</p>
        </div>
        <br />
        {/* <Subscribe /> */}
        {/* <Iframe iframe={iframe} /> */}
        {/* <p className="font-rob text-md">For the wedding we are using another website. RSVP and more!</p>
        <a href="https://theknot.com/joeandjayna" target="__blank"><button className="bg-white outline outline-2 outline-black hover:bg-black hover:text-white text-black font-bold py-2 px-4 focus:outline-none focus:shadow-outline cursor-pointer uppercase mt-4">RSVP</button></a> */}
        <a href="https://zc.vg/wy34B" target="_blank" rel="noreferrer">Subscribe to our Newsletter!</a>
        {/* <div dangerouslySetInnerHTML={{ __html: myHtmlSub ?? null }} /> */}
      </div>
        {/* <JJ className="w-16" /> */}
        <div className="jj-logo" />
      </div>
    </div>
    <div className="absolute left-0 bottom-0 p-1">
      {/* <small>Website by yours truly :)</small> */}
    </div>
    <div className="fixed hidden md:inline right-0 bottom-0 w-60 md:w-4/12 max-w-lg z-10" style={{ pointerEvents: 'none' }}>
      <SageBottomRight />
    </div>
  </div>
)}

export default IndexPage
